import React from "react";

export const Navigation = (props) => {
  const handleNavClick = (event, target) => {
    event.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
      window.history.pushState(null, null, " ");
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a href="#page-top" className="navbar-brand">
            <img
              src="img/you-turn-symbol.png"
              alt="You-Turn Logistics Logo"
              style={{
                width: 'auto',
                height: '70px',
                marginRight: '10px',
              }}
              onClick={(e) => handleNavClick(e, "#page-top")}
            />
            {/* Company Name */}
            <span className="navbar-company-name">
              You-Turn Logistics
            </span>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#features"
                className="page-scroll"
                onClick={(e) => handleNavClick(e, "#features")}
              >
                Our Values
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="page-scroll"
                onClick={(e) => handleNavClick(e, "#about")}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="page-scroll"
                onClick={(e) => handleNavClick(e, "#services")}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#modal-form"
                className="page-scroll"
                onClick={(e) => handleNavClick(e, "#modal-form")}
              >
                Request A Quote
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={(e) => handleNavClick(e, "#contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
