import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

export const ModalComponent = () => {
  const [show, setShow] = useState(false);
  const [closing, setClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    phone: "",
    message: "",
  });

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setShow(false);
      setClosing(false);
      setFormData({
        from_name: "",
        from_email: "",
        phone: "",
        message: "",
      });
    }, 300);
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_vkdsqyw", // Replace with your Service ID
        "template_273qm37", // Replace with your Template ID
        e.target,           // Pass the form element
        "PnzSXC0m-pVVW7jav" // Replace with your Public Key
      )
      .then(
        (result) => {
          toast.success("Quote request sent successfully!");
          setIsSubmitting(false);
          handleClose();
        },
        (error) => {
          toast.error("Failed to send request. Please try again.");
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div id="modal-form" className="text-center">
      <ToastContainer />
      <div style={{ marginTop: "5%" }} className="flex-container">
        <div className="section-title">
          <h2>Request a Quote</h2>
          <p>
            Please fill out the form below, and we will get back to you as soon
            as possible.
          </p>
        </div>
        <button className="btn btn-custom btn-lg" onClick={handleShow}>
          Request a Quote
        </button>
      </div>
      {show && (
        <div className={`modal-overlay ${closing ? "hide" : ""}`}>
          <div className={`modal-content ${closing ? "hide" : ""}`}>
            <span className="close-btn" onClick={handleClose}>
              &times;
            </span>
            <h2 className="modal-title">Request a Quote</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name *</label>
                <input
                  type="text"
                  id="name"
                  name="from_name"  // Ensure it matches EmailJS template placeholder {{from_name}}
                  value={formData.from_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="from_email"  // Ensure it matches EmailJS template placeholder {{from_email}}
                  value={formData.from_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"  // Ensure it matches EmailJS template placeholder {{phone}}
                  value={formData.phone}
                  onChange={handleChange}
                  inputMode="numeric"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message *</label>
                <textarea
                  id="message"
                  name="message"  // Ensure it matches EmailJS template placeholder {{message}}
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  style={{ resize: "none" }}
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-custom btn-lg"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
